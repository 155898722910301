import { useNavigate, Navigate } from "react-router-dom";
import { useEffect } from 'react'
import Cookie from 'universal-cookie'


 function Logout() {

    const cookie = new Cookie();
    const navigate = useNavigate();

    useEffect(() => {
        fetch(process.env.REACT_APP_URL + '/user/logout', {
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    }).then(() => {
        cookie.remove('accessToken')
        navigate('/login') 
    }
    )})
}


export default Logout;