import React, { Fragment, useEffect, useState, useRef } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import randomstring from 'randomstring'
import Header from '../..//comps/header.jsx';
import Navbar from '../..//comps/navbar.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScissors, faPaste, faCopy, faFolder, faFile, faPencil, faFileCirclePlus, faFolderPlus, faTrashCan, faPenToSquare, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';

import '../../styles/styles.css';
import '../../styles/partstyles.css';
import './styles/explorer.css'
import './styles/documents.css'

function Documents() {
    const [list, setList] = useState({});
    const [newName, setNameInput] = useState('')
    const [openFolders, setOpenFolders] = useState([]);
    const [openInput, setInput] = useState('')
    const [clipBoard, setClipBoard] = useState(null)
    const [popupOpen, setPopup] = useState(false)
    const [deleteItem, setDeleteItem] = useState();

    useEffect(() => {
        (async () => {
      const response = await fetch(process.env.REACT_APP_URL + '/write/documents', {
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }) 
       const data = await response.json()
       console.log(data)
       setList(data.explorer) 
    })()
    
    }, [])

    async function findFolder(folder, action, id, option, tree){
        if (!tree) tree = {...list}
        
        console.log(tree)
        if(folder === 'main') {action({children:tree}, tree, id, option)} else {
        search(tree, folder, id, option)
            function search(arr, folder, id, option){
                Object.values(arr).find((child)=>{
                    if (child.id === folder) {
                        action(child, tree, id, option); 
                    }else if (child.type === 'folder' && openFolders.includes(child.id)){
                        search(child.children, folder, id, option);
                    }
                })
            }
        }
    }

    function generateID(){
        let date = new Date
        date = date.getDate() + '' + date.getMonth() + '' + date.getFullYear() + '' + date.getHours() + '' + date.getMinutes() + '' + date.getSeconds() + '' + date.getMilliseconds()
        const id = date + randomstring.generate(16)
        return id
    }

    function createFolder(parentFolder, tree){
        let newID = generateID()
        const folder = {name: 'Neuer Ordner', id:newID, type: 'folder', children: {}}
        parentFolder.children[newID] = folder
        setList(tree)
        uploadChanges(tree)
    }

    async function uploadChanges(tree){
        if(tree === undefined) tree = list
        
        const response = await fetch(process.env.REACT_APP_URL + '/write/updateexplorer', {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              tree,
          })})
          console.log(response)
    }
    async function fetchCreateDocument(id) {
        const response = await fetch(process.env.REACT_APP_URL + '/write/createdocument', {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              id,
        })})
        console.log(response)
    }

    async function fetchDeleteDocument(id) {
        const response = await fetch(process.env.REACT_APP_URL + '/write/deletedocument', {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              id,
        })})
        console.log(response)
    }
 
    function createDocument(parentFolder, tree){
        let newID = generateID()
        console.log(newID)
        const folder = {name: 'Neues Dokument', id:newID, type: 'document'}
        parentFolder.children[newID] = folder
        setList(tree)

        fetchCreateDocument(newID)
        uploadChanges(tree)
    }

    function deleteDocument(parentFolder, tree, id){
        console.log(tree)
        delete parentFolder.children[id]
        setList(tree)

        uploadChanges(tree)
        fetchDeleteDocument(id)
    }

    function setNewName(item, tree, id, value) {
        
        item.name = value
        setList(tree)
    }
    
    async function changeDocumentName(id, newname){
        const response = await fetch(process.env.REACT_APP_URL + '/write/changedocname', {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              id,
              newname,
        })})
        console.log(response)
    }

    async function handleUnfocus(id, type, value){
        console.log("unblur : " + value)
        findFolder(id, setNewName, id, value)

        const docHeader = document.getElementById(id)
        docHeader.style.visibility='visible'
        docHeader.style.position='relative'
        const nameInput = document.getElementById("input"+id)
        nameInput.style.visibility='hidden'
        nameInput.style.position='absolute'
        console.log(type)
        changeDocumentName(id, value)

        
        uploadChanges(list)
    }

    

    function placeInputField(id, name) {
        
        const docName = document.getElementById(id)
        docName.style.visibility='hidden'
        docName.style.position='absolute'
        const nameInput = document.getElementById("input"+id)
        nameInput.style.visibility='visible'
        nameInput.style.position='relative'
        nameInput.focus()
    }

    function extendFolder(id, event){
        if(event.target.checked === true){
            setOpenFolders([...openFolders, id])
        } else {
            setOpenFolders(openFolders.filter((folderid)=>{return folderid !== id}))
        }
    }

    const explorer = Object.values(list).map((child) => {
        return (child.type === 'folder') ? 
            <Folder name={child.name} id={child.id} children={child.children} parentFolder={'main'}/> : 
            <Document name={child.name} id={child.id} parentFolder={'main'}/>
    })

    function copyTo(parentFolder, tree, id) {
        if (clipBoard !== null) {
            console.log(parentFolder)
        console.log(clipBoard)
        //if (parentFolder === 'main') {list[clipBoard.id] = clipBoard} 
        parentFolder.children[clipBoard.id] = clipBoard
        console.log(tree)
        setList(tree)
        findFolder(clipBoard.parentFolder, deleteDocument, clipBoard.id, null, tree)
        setClipBoard(null)
        }
    }

    function togglePopup() {
        setPopup(!popupOpen)
    }

    function Popup({}){
        
          return (
            (popupOpen) ? 
            <div className='popup'>
              <div className='popup_inner'>
                <div className='popupheader'>
                <h1>Löschen?</h1>
              <button onClick={togglePopup}><i><FontAwesomeIcon icon={faXmark}/></i></button>
              </div>
              <div className='popupbody'>
                <p>
                    Willst du {deleteItem.name} wirklich löschen?
                </p>
              </div>
              <div className='popupbuttons'>
              <button onClick={togglePopup}>Abbrechen</button>
              <button onClick={()=>{findFolder(deleteItem.parentFolder, deleteDocument, deleteItem.id); togglePopup()} }>Löschen</button>
              </div>
              </div>
            </div> 
            : null
          );
          
      }

    function Document({name, id, parentFolder, last}){
        let type = 'file'
        let displayname = name
        if(name.split('').length >= 15) displayname = name.split('').slice(0, 15).join('') + '...'  
        
        return (
        <div key={id} className='document'> 
        <div className={(last === true) ? "lastvertline" : "vertline"}/>
        <div className="file">
        <a style={{textDecoration:'none'}} href={`/write?documentid=${id}`} className='info'>
        <div className='line'/>
        
        <i className='itemicon'><FontAwesomeIcon icon={faFile} /></i>
            <h4 id={id} className='foldername'>{displayname}</h4>
            <input
            className='nameinput'
            defaultValue={name}
                style={{visibility:"hidden", position:"absolute"}}
                id={'input'+id} 
                onKeyDown={(e) => {
                    if (e.key === "Enter")
                        handleUnfocus(id, type, e.target.value);
                    }} 
                onBlur={(event)=> {handleUnfocus(id, type, event.target.value)}} >
            </input>
        </a>
        <div className='options'>
            <button onClick={()=>{placeInputField(id, name)}}><i><FontAwesomeIcon icon={faPencil}/></i></button> 
            <button><a href={`/write?documentid=${id}`}><i><FontAwesomeIcon icon={faPenToSquare}/></i> </a></button>
            <button className='copybutton' onClick={()=>{setClipBoard({id:id, name:name, type:'document', parentFolder: parentFolder})}}><i><FontAwesomeIcon icon={faScissors}/></i></button>
            <button className="delbutton" onClick={()=>{togglePopup(); setDeleteItem({parentFolder:parentFolder, id:id, name:name})}}><i><FontAwesomeIcon icon={faTrashCan}/></i></button>
        </div></div></div>
        )
    }

    function Folder({name, id, children, parentFolder, last}) {
        let displayname = name
        let type = 'file'
        if(name.split('').length >= 15) displayname = name.split('').slice(0, 15).join('') + '...'  
        
        return (
            <div key={id} className='folder'>
            <div className={(last === true) ? "lastvertline" : "vertline"}/>
            <div className='folderheader'>
                <div className='line'/>
                <div onClick={()=>{document.getElementById("check"+id).click()}} className='info' >
                <i className='itemicon'><FontAwesomeIcon icon={faFolder} /></i>
                <h4 id={id} className='foldername'>{displayname}</h4>
                <input className='nameinput'
                    defaultValue={name}
                    style={{visibility:"hidden", position:"absolute"}}
                    id={'input'+id} 
                    onKeyDown={(e) => {
                        if (e.key === "Enter")
                            handleUnfocus(id, type, e.target.value);
                        }} 
                    onBlur={(event)=> {handleUnfocus(id, type, event.target.value)}} >
                </input>
                </div>
                <div className='options'>
            <button onClick={()=>{placeInputField(id, name)}}><i><FontAwesomeIcon icon={faPencil}/></i></button> 
                        <button className='newbutton' onClick={()=>{findFolder(id, createDocument); let e = document.getElementById("check"+id); if (e.checked == false) e.click()}}><i><FontAwesomeIcon icon={faFileCirclePlus}/></i></button>
                        <button className='newbutton' onClick={()=>{findFolder(id, createFolder); let e = document.getElementById("check"+id); if (e.checked == false) e.click()}}><i><FontAwesomeIcon icon={faFolderPlus}/></i></button>
                        <button className='copybutton' onClick={()=>{setClipBoard({id:id, name:name, type:'folder', children:children, parentFolder: parentFolder})}}><i><FontAwesomeIcon icon={faScissors}/></i></button>
                        <button className='pastebutton' onClick={()=>{findFolder(id, copyTo, clipBoard)}}><i><FontAwesomeIcon icon={faPaste}/></i></button>

                        <button className="delbutton" onClick={()=>{togglePopup(); setDeleteItem({parentFolder:parentFolder, id:id, name:name})}}><i><FontAwesomeIcon icon={faTrashCan}/></i></button>
                    <label><input id={"check" + id} type='checkbox' defaultChecked={(openFolders.includes(id)) ? true : false} onClick={(event)=>{extendFolder(id, event)}}>
                    </input> <i className='chevron'><FontAwesomeIcon icon={faChevronRight}/></i></label>
                </div>
                </div>
                {(openFolders.includes(id)) ?
                <div className='children'>
                    
                    {Object.values(children).map((child, i) => {
                        last = false
                        if (i + 1 === Object.values(children).length) last = true
                        return (child.type === 'folder') ? 
                            <Folder last={last} name={child.name} id={child.id} parentFolder={id} children={child.children}/> : 
                            <Document last={last} name={child.name} id={child.id} parentFolder={id}/>
                    })}  
                </div> : null
                }
            </div>
        )
    }

    function CreateNewButton() {
        return (
            <div className='newitem'>
            <button className="createbutton" onClick={ ()=>{findFolder('main', createFolder)}}><i><FontAwesomeIcon icon={faFolderPlus}/></i>Ordner erstellen</button>
            <button className="createbutton" onClick={ ()=>{findFolder('main', createDocument)}}><i><FontAwesomeIcon icon={faFileCirclePlus}/></i>Dokument erstellen</button>
            <button className='createbutton' onClick={()=>{findFolder('main', copyTo, clipBoard)}}><i><FontAwesomeIcon icon={faPaste}/></i>Einfügen</button>
        </div>
        )
    }


    return (
        <Fragment>
            <Popup/>
       <Navbar/>
        <div className="container">
            
        <Header page={{title:'Dokumente'}}/>
        
            <div className='page' id='noscroll'>
                <div className='spacer'>
                    <div className='buttons'>
                        <button className="createbutton" onClick={ () =>{findFolder('main', createDocument)}}><i><FontAwesomeIcon icon={faFileCirclePlus}/></i>Dokument erstellen</button>
                        <button className="createbutton" onClick={ () =>{findFolder('main', createFolder)}}><i><FontAwesomeIcon icon={faFolderPlus}/></i>Ordner erstellen</button>
                        <button className='createbutton' onClick={ () =>{findFolder('main', copyTo, clipBoard)}}><i><FontAwesomeIcon icon={faPaste}/></i>Einfügen</button>
                    </div>
                        <div className='clipboard'>
                            <h4></h4> {(clipBoard === null) ? <div className='copied'><h4>Nichts kopiert!</h4></div> : 
                            <div className='copied'>{(clipBoard.type === 'folder') ? <i className='itemicon'><FontAwesomeIcon icon={faFolder} /></i> : <i className='itemicon'><FontAwesomeIcon icon={faFile} /></i>}
                                <h4>{clipBoard.name}</h4></div>}
                        </div>
                </div> 
                <div className='filepage'>
                    <div className='explorer'>
                        {explorer}
                    </div>

                
            </div>
            
        
        
        </div>
        
        </div>
    </Fragment>
    )
  

}



export default Documents;