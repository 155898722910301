import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter, Routes, Route, redirect, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group" 
import '../styles/partstyles.css';
import '../styles/extendedsidebar.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faListCheck, faPenToSquare, faPersonChalkboard, faPersonWalkingArrowRight, faBars } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane, faCalendarDays } from '@fortawesome/free-regular-svg-icons';


 function Header() {
    const [settings, setSettings] = useState({});
    const [extended, setExtended] = useState(false)

    useEffect(() => {
    fetch(process.env.REACT_APP_URL + '/settings/getsettings', {
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
      }).then((response) => {
          return response.json()
      }).then((data) => {
          setSettings(data)
          
      })
  }, [])

  function extendBar() {
    extended ? setExtended(false) : setExtended(true)
  }
    
  return (
    <CSSTransition 
    in={extended} 
    timeout={500}
    classNames="sidenav" 
  >  
        <div className={extended ? 'extendedSidebar' : 'sidebar'}>
          <div className="between" onClick={(() => {extendBar()})}>
        <i><FontAwesomeIcon icon={faBars} /></i>
        {extended ? <h2>Navigation</h2> : null}
        </div>
        <ul>
            <li href="/start"><a href="/home"><i><FontAwesomeIcon icon={faHouse} /></i><h4 className='pagename'>Start</h4></a><p className="start">Start</p></li>
            <li href="/plan"><a href="/plan"><i><FontAwesomeIcon icon={faCalendarDays} /></i><h4 className='pagename'>Stundenplan</h4> </a><p className="plantab">Stundenplan</p></li>
            <li href="/tasks"><a href="/tasks"><i><FontAwesomeIcon icon={faListCheck} /></i><h4 className='pagename'>Hausaufgaben</h4> </a><p className="tasks">Hausaufgaben</p></li>
            <li href="/documents"><a href="/documents"><i><FontAwesomeIcon icon={faPenToSquare} /></i><h4 className='pagename'>Notizen</h4> </a><p className="write">Notizen</p></li>
            {(settings.developermode == true) ?
            <ul>
              
            <li href="/lessons"><a href="/lessons"><i><FontAwesomeIcon icon={faPersonChalkboard} /></i><h4 className='pagename'>Unterricht</h4> </a><p className="lessons">Unterricht</p></li>
            <li><a href="/assistant"><i><FontAwesomeIcon icon={faPaperPlane} /></i><h4 className='pagename'>Assistent</h4> </a><p className="lessons">Assistent</p></li> </ul>
            : null }
        </ul>
        
            <a className="exit" href="https://google.com"><i><FontAwesomeIcon icon={faPersonWalkingArrowRight} /></i></a>
        
    </div>
    </CSSTransition>

  );
}

export default Header;