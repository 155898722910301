import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Header from './/comps/header.jsx';
import Navbar from './/comps/navbar.jsx';
import './styles/home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons';
//import './styles/partstyles.css';





function Home() {
  const [undone, setUndone] = useState([])
  const [news, setNews] = useState([])
  const [totalundone, setTotal] = useState(0)

  async function post(url, data){
    const response = await fetch(process.env.REACT_APP_URL + '/'+url, {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data),
    })
    console.log(response)
    return response
}

  useEffect(() => {
      
    fetch(process.env.REACT_APP_URL+'/homework/getundone', {
      method: 'get',
      credentials: 'include',
      headers: {'Content-Type': 'application/json'},
      }).then((response) => {
          return response.json()
      }).then((data) => {
          setUndone(data.undone)
          console.log(data)
          setTotal(data.undone.length)
        })

    fetch(process.env.REACT_APP_URL+'/news/getnews', {
      method: 'get',
      credentials: 'include',
      headers: {'Content-Type': 'application/json'},
      }).then((response) => {
          return response.json()
      }).then((data) => {
          setNews(data.news)
          
        })
        
  }, []);

  function interpretLineBreaks(text) {
    let newtext = text.split("*/nl")
      return newtext.map((textpiece)=>(
        <p>
          {textpiece}
          <br/>
        </p>
      ))
  }

  function checkTask(week, day, time) {
     setUndone(undone.filter((task)=>{if(!(task.relweek == week && task.day == day && task.time == time)) return task}))
     post('homework/checktask', {week, day, time})
  }

  const weekDays = ["Montag","Dienstag","Mittwoch","Donnerstag","Freitag"]
  const week = ["Diese Woche", "Nächste Woche", "Übernächste Woche"]

  const undonelist = undone.map((task) => (
    <div className='undonetask'>
      <div className="undoneheader">
        <p className='undonelesson' style={{backgroundColor: `${task.color}`}}>{task.lesson}</p>
        <p className='undonedate' >{week[task.relweek]} {weekDays[task.day - 1]}</p >
      </div>
      <div className='undonebody'>
        <p>{task.untis.map((x)=>x.task) +"\n"+ task.default}</p>
        <div>
          <button onClick={()=>{checkTask(task.relweek, task.day, task.time)}}>Erledigt</button>
        </div>
      </div>
    </div>
  ))

  const newslist = news.map((article) => {
    let date = new Date(article.date)
    console.log(date.getMonth())
    let dateString =
        (date.getDate() > 9 ? date.getDate() : "0"+date.getDate()) + "."
        + (date.getMonth() > 8 ? (date.getMonth() + 1) : "0"+(date.getMonth() + 1)) + "." 
        + date.getFullYear()
      console.log(dateString)
    let timeString = date.getHours() + ":" + date.getMinutes()
    console.log(dateString)
    return (
    <div className='article'>
      <div className="articleheader">
        <p className='articletitle'>{article.title}</p>
        <p className='articledate'>{dateString} {timeString}</p>
        
      </div>
      <div className='articlebody'>
        <p>{interpretLineBreaks(article.text)}</p>
      </div>
    </div>
    )
  })

    return (
      <Fragment>  
          
          
          <Navbar/>
          <div className="container">
            <Header page={{title:'Start'}}/>
            <div className="page">
              <div className="vertalign">
                <div className='stack-start'>
                  <div className='header-start'>
                    <p>Aktuelle Neuigkeiten / Updates</p>
                  </div>
                  <div className='news-start'>
                    <div className='news-inner'>
                      {newslist}
                    </div>
                  </div>
                </div>
                <div className="undonecontainer"><div className='flex'>
                <h2>Nicht erledigte Hausaufgaben</h2> <div className='undone-total'><p>{totalundone}</p></div></div>
                  <div className='undonetasks'> 
                  
                    {undone.length == 0 ? <div className='notasks'><FontAwesomeIcon icon={faFaceSmile}/> <p>Keine anstehenden Hausaufgaben</p></div>: undonelist}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </Fragment>

    )
  
}



export default Home;
