import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, redirect, useNavigate } from "react-router-dom";
import './styles/styles.css';
import Cookie from 'universal-cookie'

function SignUp() {
    const [username, setName] = useState('')
    const [password, setPassword] = useState('')
    const [email, setMail] = useState('')
    const [key, setKey] = useState('')
    const [error, setError] = useState('')

    const cookie = new Cookie();
    const navigate = useNavigate();

    async function handleSignUp(event) {
        event.preventDefault()
        const response = await fetch(process.env.REACT_APP_URL + '/user/signup', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username,
                password,
                email,
                key
            }),
        }) 

        const data = await response.json()
        console.log(data)
        
        if (data.status == 'ok') {
            navigate('/verify')
            
            cookie.set('signUpToken', data.token, {
                maxAge: data.maxAge
            })

        } else if(data.error) {
            setError(data.error)
        }
    }
    

  return (
    <body id='auth'>
    <div className="centerbox">
        <div className="menu-center">
            <div className="title">
                <h2 className="header">Registrieren</h2>
            </div>
                <form action ="/signup" method="post" onSubmit={handleSignUp} className="form" autocomplete="off">
                <div className="normal-input">
                            <p>
                                Willkommen bei Schoolkit!
                            </p>
                        <div>
                    <div>
                        <input type="text" name="username" onChange={(e) => setName(e.target.value)} placeholder="Name" autocomplete="off"/>
                    </div>
                    <div>
                        <input type="password" name="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" autocomplete="off"/>
                    </div>
                    <div>
                        <input type="text" name="email" onChange={(e) => setMail(e.target.value)} placeholder="E-Mail" autocomplete="off"/>
                    </div>
                    <div>
                        <input type="text" name="key" onChange={(e) => setKey(e.target.value)} placeholder="1-mal-Schlüssel (Nur von Admins ausgestellt)" autocomplete="off"/>
                    </div>
                    <div className="error">{error}</div>
                </div>
                </div>

                <input type="submit" value="Absenden" className="yellow-btn"/>
                
                </form>

    <a href="/login">Du hast bereits einen Account? Hier anmelden</a>
</div> 
</div> 
    
    
</body>
  );
}

export default SignUp;