import { Navigate } from 'react-router-dom'
import { Outlet } from 'react-router'
import { useState, useEffect } from 'react'

const CheckVerify = () => {
    const [state, setState] = useState(undefined)

    useEffect(() => {
        fetch(process.env.REACT_APP_URL + '/checkverify', {
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    }).then((response) => {
        return response.json()
    }).then((data) => {
        console.log(data)
        if (data.authed == true) {
           setState(true) 
        }else {
            setState(null)
        }
    })
}, []);
    if( state === undefined){
        return null
    }
    if(!state){
        return <Navigate to='/login'/>
    }

    return <Outlet/>
}


export default CheckVerify