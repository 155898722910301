import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import Header from '../../comps/header.jsx';
import Navbar from '../../comps/navbar.jsx';
import '../../styles/styles.css';
import '../../styles/partstyles.css';
import '../../styles/planstyles.css';

function Plan() {
    
    

    const [plan, setPlan] = useState([])
    const [planName, setName] = useState('')

    useEffect(() => {
        fetch(process.env.REACT_APP_URL + '/plan/getselectedplan', {
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    }).then((response) => {
        return response.json()
    }).then((data) => {
        console.log(data)
        setPlan(data.plan)
        setName(data.planname)
        
    })
}, []);
    
    const lessonlist = plan != null ? plan.map((lesson) => (
        <tr key={lesson.time} className={lesson.type}>
            <td className={'lessonfield ths ' + lesson.type}><p className='start'>{lesson.from}</p><h4>{lesson.lesson}</h4><p className='end'>{lesson.to}</p></td>
                <td className={'lessonfield mo ' + lesson.type}><div className='innerlesson' style={{backgroundColor: `${lesson.mocolor}`}}><h4>{lesson.mo} </h4><h6>{lesson.moteacher}</h6><p>{lesson.moroom}</p></div></td>
                <td className={'lessonfield di ' + lesson.type}><div className='innerlesson' style={{backgroundColor: `${lesson.dicolor}`}}><h4>{lesson.di} </h4><h6>{lesson.diteacher}</h6><p>{lesson.diroom}</p></div></td>
                <td className={'lessonfield mi ' + lesson.type}><div className='innerlesson' style={{backgroundColor: `${lesson.micolor}`}}><h4>{lesson.mi} </h4><h6>{lesson.miteacher}</h6><p>{lesson.miroom}</p></div></td>
                <td className={'lessonfield do ' + lesson.type}><div className='innerlesson' style={{backgroundColor: `${lesson.docolor}`}}><h4>{lesson.do} </h4><h6>{lesson.doteacher}</h6><p>{lesson.doroom}</p></div></td>
                <td className={'lessonfield fr ' + lesson.type}><div className='innerlesson' style={{backgroundColor: `${lesson.frcolor}`}}><h4>{lesson.fr} </h4><h6>{lesson.frteacher}</h6><p>{lesson.frroom}</p></div></td>
        </tr>
    )) : null;
    function Table(){
        if(plan != null){
        return (
            <div className="plan">
                <div className='tabletitle'>
                            <h1>{planName}</h1>
                            <a href='/manageplans'><i><FontAwesomeIcon icon={faPenToSquare}/></i></a>
                        </div>
            <table>
            <tr>
                <th className='headerday corner'><FontAwesomeIcon icon={faClock} /></th>
                <th className='headerday'>Mo</th>
                <th className='headerday'>Di</th>
                <th className='headerday'>Mi</th>
                <th className='headerday'>Do</th>
                <th className='headerday'>Fr</th>
            </tr>
            
            
            {lessonlist}
        </table>
        </div>
        )
        }else {
            return (
                <div className="noplan-container">
                    <div className="noplan">
                        <p>Du hast noch keinen Stundenplan hinzugefügt oder festgelegt</p>
                        <a href="/manageplans">+ Neu erstellen</a>
                    </div>
                </div>
            )
        }
    }
    return (
      <Fragment>
          <Navbar />
          <div className="container">
            <Header page={{title:'Stundenplan'}}/>
            <div className='page'>
                <div className='centralizer'>
                    <div>
                        
                        <Table/>
                    </div>
                </div>
          </div>
        </div>
      </Fragment>

    )
  
}



export default Plan;