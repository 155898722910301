import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './pages/login.jsx';
import SignUp from './pages/signup.jsx';
import Home from './pages/home.jsx';
import Settings from './pages/app/settings.jsx';
import Profile from './pages/app/profile.jsx';

import Plan from './pages/app/plan/plan.jsx';
import EditPlan from './pages/app/plan/editplan.jsx';
import ManagePlan from './pages/app/plan/manageplans.jsx';

import Documents from './pages/app/texteditor/documents.jsx';
import Tasks from './pages/app/homework/tasks.jsx';
import Write from './pages/app/texteditor/write.jsx';
import Lessons from './pages/app/lessons.jsx';
import Assistant from './pages/app/assistant.jsx';
import Verify from './pages/verify.jsx';
import Auth from './checkAuth.jsx'
import CheckVerify from './checkVerify.jsx'
import NotAuth from './checkNotAuth.jsx'
import Logout from './pages/logout.jsx';
import NoPage from './pages/nopage.jsx';

//import ProtectedRoutes from './routes.jsx';

function App() {
  console.log(process.env.REACT_APP_URL)

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NoPage />} />
        <Route path="/logout" element={<Logout />} />
        <Route element={<NotAuth/>} >
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
        <Route element={<CheckVerify/>} >
          <Route path="/verify" element={<Verify />} />
        </Route>
        </Route>
        <Route element={<Auth />} >
          <Route path="/settings" element={<Settings /> } />
          <Route path="/profile" element={<Profile /> } />

          <Route path="/home" element={<Home /> } />
          <Route path="/plan" element={<Plan /> } />
          <Route path="/editplan" element={<EditPlan /> } />
          <Route path="/manageplans" element={<ManagePlan /> } />
          <Route path="/tasks" element={<Tasks /> } />
          <Route path="/documents" element={<Documents /> } />
          <Route path="/write" element={<Write /> } />
          <Route path="/lessons" element={<Lessons /> } />
          <Route path="/assistant" element={<Assistant /> } />


      </Route>

      </Routes>
    </BrowserRouter>
  );
}
export default App;
