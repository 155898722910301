import React, { Fragment, useEffect, useState, useRef, useCallback} from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Header from '../../comps/header.jsx';
import Navbar from '../../comps/navbar.jsx';
import '../../styles/styles.css';
import '../../styles/partstyles.css';
import 'react-quill/dist/quill.snow.css'
import { faDisplay } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from "react-quill";
import { modules, formats } from "./editorToolbar.jsx";
import EditorToolbar from "./editorToolbar.jsx";
import './styles/editor.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

function Write() {
  const queryParameters = new URLSearchParams(window.location.search)
  let id = queryParameters.get("documentid")
  const [state, setState] = useState({ value: null });
  const [name, setName] = useState('')

  useEffect(() => {
    fetch(process.env.REACT_APP_URL + '/write/getdocumentvalue', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id,
    }),
      }).then((response) => {
          return response.json()
      }).then((data) => {
        setName(data.name)
        setState({value: data.value})
      })
  }, [])


  async function Save(stateC) {
    console.log(stateC)

    const response = await fetch(process.env.REACT_APP_URL + '/write/savedocument', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id,
        stateC,
    })})
    console.log(response)
  }
  
  function handleChange(value) {
    setState({ value: value });
    Save({value: value})
  };

  return (
    <Fragment>
        <Navbar/>
        <div className="container">
          <Header page={{title:'Texteditor'}}/>
          <div className='page' id='noscroll'>
        <div id='page'>
        <div id='documentoptions'>
            <h5>{name}</h5>
            <button onClick={()=>{Save(state)}}><i><FontAwesomeIcon icon={faFloppyDisk} /></i></button>
          </div>
        <EditorToolbar />
          <div id='document'>
            <div className='sheet'>
              <div className="text-editor">
                <ReactQuill
                  theme="snow"
                  value={state.value}
                  onChange={handleChange}
                  modules={modules}
                  formats={formats}
                />
              </div>
            </div>
            </div>
          </div>
          </div>
        </div>
    </Fragment>
  )
}



export default Write;