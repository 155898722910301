import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, redirect, useNavigate } from "react-router-dom";
import '../styles/partstyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faRightFromBracket, faBars} from '@fortawesome/free-solid-svg-icons';
import { faCircleUser } from '@fortawesome/free-regular-svg-icons';


 function Header({page}) {
    
    
  return (
    
        <div className="navbar">
            <div className="logo">
                
              <h2>ClassMaster</h2>
              
            </div>
            <h3>{page.title}</h3>
            <ul className="links">
                <li><a href="/profile"><FontAwesomeIcon icon={faCircleUser} /></a></li>
                <li><a href="/settings"><FontAwesomeIcon icon={faGear} /></a></li>
                <li><a href="/logout"><FontAwesomeIcon icon={faRightFromBracket} /></a></li>
            </ul>
          </div>

  );
}

export default Header;