import React, { Fragment, useEffect, useState, useRef } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrashCan, faPenToSquare, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from "react-transition-group" 
import Header from '.././/comps/header.jsx';
import Navbar from '.././/comps/navbar.jsx';
import '.././styles/styles.css';
import '.././styles/partstyles.css';
import '.././styles/settings.css';


function Settings() {
  const [settings, setSettings] = useState({})
  const [popupOpen, setPopup] = useState(false)
  const [searchQuery, setQuery] = useState("")
  const [searchResult, setResult] = useState(null)
  const [selectedSchool, setCurrentSchool] = useState({schoolid:0, schoolname:"", schoolserver:"", displayName:"", address:""})
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [statusMSG, setStatus] = useState("")
  const nodeRef = useRef(null);

  useEffect(()=>{
          
    fetch(process.env.REACT_APP_URL + '/settings/getsettings', {
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
      }).then((response) => {
          return response.json()
      }).then((data) => {
          setSettings(data)
          console.log(data)
      })

    fetch(process.env.REACT_APP_URL + '/settings/getschool', {
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            setCurrentSchool(data)
            console.log(data)
        })
  }, [])

  async function post(url, data){
    const response = await fetch(process.env.REACT_APP_URL + '/' + url, {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data),
    })
    return response

}

  function updateSetting(id, value) {
    //settings[id] = event.target.checked
    let settingvalue = value
    console.log(settingvalue)
    setSettings({...settings, [id]: settingvalue })
    
      fetch(process.env.REACT_APP_URL + '/settings/setsetting', {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            id,
            settingvalue
        }),
    })
    

  }

  function togglePopup() {
    setPopup(!popupOpen)
}

  async function setSchool(id, name, server, display, address){
    console.log(id, name, server)
    setCurrentSchool({schoolid:id, schoolname:name, schoolserver:server, displayName:display, address:address})
    post("settings/setschool", {id, name, server, display, address})
  }

async function reqSchools(query) {
  try {
    let response = await post("settings/searchuntisschools", {query})
    const result = await response.json()
    console.log(result)
    
    if (result.status == 1) {setResult(result.schools)} else setResult("notfound")
    
  }catch (err) {console.log(err)}
}
const schoolList = searchResult != null ? searchResult != "notfound" ? searchResult.length != 0 ? searchResult.map((school)=> (
  <div className='school'>
    <h4>{school.displayName}</h4>
    <p>{school.address}</p>
    <button onClick={()=>{setSchool(school.schoolId, school.loginName, school.server, school.displayName, school.address)}}>+</button>
  </div>
)) : <div><p>Keine Ergebnisse!</p></div> : <div><p>Zu viele Ergebnisse, bitte genauer angeben!</p></div> : null

async function getUntisData(){
  console.log(selectedSchool)
  const response = await post("settings/adduntisdata", {schoolname:selectedSchool.schoolname, username:username, password:password, schoolserver:selectedSchool.schoolserver})
  let result = response.json()
  setStatus(result.msg)
  
  console.log(result)
} 

  function Popup(){
        
    return (
      (popupOpen) ? 
      <div className='popup'>
        <div className='popup_inner_untis'>
          <div className='popupheader'>
          <h1>Webuntis Verknüpfung</h1>
        <button onClick={togglePopup}><i><FontAwesomeIcon icon={faXmark}/></i></button>
        </div>
        <div className='popupbody_untis'>
          <input placeholder='Schule suchen' type="text" defaultValue={searchQuery} onChange={(e)=>reqSchools(e.target.value)}></input>
          <p></p>
            <div className='schoolslist'>
              {schoolList != null ? schoolList : null}
            </div>

            <div className='selectedschool'>
              <h4>{selectedSchool.displayName}</h4>
              <p>{selectedSchool.address}</p>
            </div>
            <input placeholder='username' type="text" onChange={(e)=>setUsername(e.target.value)}></input>
            <input placeholder='password' type="text" onChange={(e)=>setPassword(e.target.value)}></input>
            <button onClick={getUntisData}>Verbinden</button>
            <p>{statusMSG}</p>
        </div>
        <div className='popupbuttons'>
        
        </div>
        </div>
      </div> 
      : null
    );
    
}
  
  
  function Option({id, name}){
    return (
      <div id={id} className='option'>
        <h5>{name}</h5>
          
            <CSSTransition 
              in={settings[id]} 
              timeout={400}
              classNames="testclass"
              nodeRef={nodeRef} 
            >     
            <div>
            <label className="switch" ref={nodeRef}>
              <input type="checkbox" defaultChecked={settings[id]} onClick={(event)=>{updateSetting(id, event.target.checked)}}/>
              <span className="slider round"></span>
            </label></div>
          </CSSTransition>
        
      </div>
    )
  }
        
    return (
      <Fragment>
        {Popup()}
          <Navbar />
          <div className="container">
          <Header page={{title:'Einstellungen'}}/>
          <div className="page">
              
            
          <div className='optionlist'>
            <h1>Allgemein</h1>
              <hr/>
                <Option name={"Entwicklermodus"} id={"developermode"}/>
              <hr/>
            <h1>Stundenplan</h1>
              <hr/>
                <Option name={"Mehrwöchiger Stundenplan"} id={"multipleweekplan"}/>
              <hr/>
                <div id={"untisconnection"} className='option'>
                  <h5>Webuntis Verknüpfung</h5>
                  <div><button onClick={() => togglePopup()}>+ Hausaufgaben von Webuntis</button></div>
                </div>
              <hr/>
          </div>
          </div>
          </div>
      </Fragment>

    )
  
}



export default Settings;