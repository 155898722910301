import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Header from '../../comps/header.jsx';
import Navbar from '../../comps/navbar.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import '../../styles/styles.css';
import '../../styles/partstyles.css';
import './tasks.css'


function Tasks() {
    const [planName, setName] = useState("Stundenplan")
    const [timetables, setTimetables] = useState({})
    const [display, setDisplay] = useState(0)
    const [checked, setChecked] = useState([])
    const [tableIDs, setIDs] = useState()
    const [timetable, setTimetable] = useState()
    const [start, setStart] = useState()
    const [end, setEnd] = useState()

    async function post(url, data){
      const response = await fetch(process.env.REACT_APP_URL + '/' + url, {
          method: 'POST',
          credentials: 'include',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(data),
      })
      return response

  }

      function addZero(num){
      return num < 10 ? "0" + num : num.toString()
    }


  async function updateHomework(time, day, e) {
    let currentTimetable = timetables[display.toString()]
    currentTimetable[time][day].text.default = e.target.value;
    let newTimeTables = {...timetables, [display.toString()]: currentTimetable}

      console.log(newTimeTables)

      setTimetables(newTimeTables)
      console.log(currentTimetable)
      //setTimetable(currentTimetable)

      post('homework/sethomework', {id: tableIDs[display.toString()], content: newTimeTables[display.toString()]})
    }

    useEffect(() => {

        fetch(process.env.REACT_APP_URL+'/homework/gethomework', {
          method: 'POST',
          credentials: 'include',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({relweek:0}),
          }).then((response) => {
              return response.json()
          }).then((data) => {
              console.log(data)
              
                setStart(addZero((new Date(data.start)).getDate()) + "." + addZero(((new Date(data.start)).getMonth() + 1)))
                setEnd(addZero((new Date(data.end)).getDate()) + "." + addZero(((new Date(data.end)).getMonth() + 1)))
              if (data.id == 1){setTimetables(null)} else {
                setTimetables({0:data.content})
                setIDs({0:data.dualID})
              }
            })
      }, []);

      async function checkTask(time, day, value){
        let currentTimetable = timetables[display.toString()]
            currentTimetable[time][day].done = !currentTimetable[time][day].done;
        let newTimeTables = {...timetables, [display.toString()]: currentTimetable}

          post('homework/sethomework', {id: tableIDs[display.toString()], content: newTimeTables[display.toString()]})
          console.log(newTimeTables)

          setTimetables(newTimeTables)


      }

      function Checkbox({time, day}) {

        return (
          
        <div>
         {timetables[display.toString()][time][day].text.default.length > 0 || timetables[display.toString()][time][day].text.untis.length > 0 ? 
          <input defaultChecked={timetables[display.toString()][time][day].done} onChange={(e)=>{checkTask(time, day, e.target.value)}} type="checkbox" class="taskdone"/>
          : null }
        </div>
        )
      }
      //console.log(display)
      //console.log(timetables[display.toString()])

      
        
        
     

      function Note({time, day}) {

        return (
        <div className='homeworkday'>
            <div className='note'>
              {timetables[display.toString()][time][day].text.untis.length > 0 ? 
                <div className='untisfield'>
                  <p>{timetables[display.toString()][time][day].text.untis.map((task1)=>task1.task)}</p>
                </div> : null}
                <div className='userfield'>
              <table className='notelines'>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
                {Array.from({length: 6},(_,index) => 
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                )}

              </table>
              
              <div className='writecontainer'>
              <textarea className='textfield' defaultValue={timetables[display.toString()][time][day].text.default} onChange={(e)=>{updateHomework(time, day, e)}} spellcheck="false" placeholder="" rows={6} name="text"></textarea>
            </div></div>
            </div>
          </div>
        )
      }

    function Llist() {
      return timetables[display.toString()] != undefined ? timetables[display.toString()].map((lesson) => (
        <tr key={lesson.mo.time + "" + display} className={'taskhz'}>

                <td className={'taskday mo lesson'}><div><div className="taskhead" style={{backgroundColor: `${lesson.mo.color}`}}><h4>{lesson.mo.lesson} </h4> {Checkbox({ time:lesson.mo.time, day:'mo'})}</div>{Note({time:lesson.mo.time, day:'mo'})}</div></td>  
                <td className={'taskday di lesson'}><div><div className="taskhead" style={{backgroundColor: `${lesson.di.color}`}}><h4>{lesson.di.lesson} </h4> {Checkbox({ time:lesson.di.time, day:'di'})}</div>{Note({time:lesson.di.time, day:'di'})}</div></td>
                <td className={'taskday mi lesson'}><div><div className="taskhead" style={{backgroundColor: `${lesson.mi.color}`}}><h4>{lesson.mi.lesson} </h4> {Checkbox({ time:lesson.mi.time, day:'mi'})}</div>{Note({time:lesson.mi.time, day:'mi'})}</div></td>
                <td className={'taskday do lesson'}><div><div className="taskhead" style={{backgroundColor: `${lesson.do.color}`}}><h4>{lesson.do.lesson} </h4> {Checkbox({ time:lesson.do.time, day:'do'})}</div>{Note({time:lesson.do.time, day:'do'})}</div></td>
                <td className={'taskday fr lesson'}><div><div className="taskhead" style={{backgroundColor: `${lesson.fr.color}`}}><h4>{lesson.fr.lesson} </h4> {Checkbox({ time:lesson.fr.time, day:'fr'})}</div>{Note({time:lesson.fr.time, day:'fr'})}</div></td>
        </tr>
    )) : null;
    }

    async function refreshTasks(relweek) {
      console.log(relweek)
      const response = await fetch(process.env.REACT_APP_URL+'/homework/gethomework', {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({relweek:relweek}),
      })

              const data = await response.json();
              console.log(data);
              //setTimetable(data.content);
              setDisplay(relweek);
              let newTimeTables = timetables;
              newTimeTables[relweek.toString()] = data.content
              //setTimetables(null)
              setTimetables(newTimeTables)
              let newIDs = tableIDs;
              newIDs[relweek.toString()] = data.dualID;
              setIDs(newIDs)

              setStart(addZero((new Date(data.start)).getDate()) + "." + addZero(((new Date(data.start)).getMonth() + 1)))
                setEnd(addZero((new Date(data.end)).getDate()) + "." + addZero(((new Date(data.end)).getMonth() + 1)))

    }

    function Table(){

      if(timetables != null){
      return (
          <div className="taskplan">
            <div className="weekheader">

                <button onClick={()=>{refreshTasks(display - 1)}}><i>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </i></button>
                <div>
                  <h2>Woche vom {start} zum {end}</h2>
                  <p>{display == 0 ? "Diese Woche" : (display > 0 ? "In " + display + " Woche" + (display == 1 ? "" : "n") : "Vor " + Math.abs(display) + " Woche" + (display == -1 ? "" : "n") )}</p>
                </div>
                <button onClick={()=>{refreshTasks(display + 1)}}>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                </i></button>
            </div>
        {}
          <table className="tasktable">
          <tr>     
              <th className='taskdayheader'>Mo</th>
              <th className='taskdayheader'>Di</th>
              <th className='taskdayheader'>Mi</th>
              <th className='taskdayheader'>Do</th>
              <th className='taskdayheader'>Fr</th>
          </tr>


          {Llist()}
      </table>
      </div>
      )
      }else {
          return (
            <div className="noplan-container">
            <div className="noplan">
                <p>Du hast noch keinen Stundenplan hinzugefügt oder festgelegt</p>
                <a href="/manageplans">+ Neu erstellen</a>
            </div>
        </div>
          )
      }
  }

    return (
      <Fragment>
        <Navbar />
          <div class="container">
          <Header page={{title:'Hausaufgabenplaner'}}/>
          <div className='page'>
            <div className='centralizer'>
                    <div className='taskbox'>       
                        {Table()}
                    </div>
                </div>
                </div>
            </div>
      </Fragment>

    )

}



export default Tasks;