import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, redirect, useNavigate } from "react-router-dom";
import './styles/styles.css';
import Cookie from 'universal-cookie'

 function Verify() {
    const [code, setCode] = useState('')
    const [error, setError] = useState('')

    const cookie = new Cookie();
    const navigate = useNavigate();

    

    async function handleLogin(event) {
        event.preventDefault()

        const signUpToken = cookie.get('signUpToken')

        const response = await fetch(process.env.REACT_APP_URL + '/user/verify', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                code,
                signUpToken
            }),
        })
        const data = await response.json()
        
        if (data.status == 'ok') {
            
            cookie.set('accesToken', data.accessToken, {
                maxAge: data.maxAge
            })
            navigate('/home')
        } else if(data.error) {
            setError(data.error)

        }
    }
    
  return (
    <body id='auth'>
    <div class="centerbox">
        <div class="menu-center">
            <div class="title">
                <h2 class="header">E-Mail verifizieren</h2>
            </div>
    <form action ="/verify" method="post" onSubmit={handleLogin} class="form">
        <div class="normal-input">
            <div>
                <input type="text" onChange={(e) => setCode(e.target.value)} name="code" placeholder="Code hier eingeben"/>
            </div>
                <div class="error">{error}</div>
        </div>
        <input type="submit" value="Verifizieren" class="yellow-btn"/>
        </form>
    </div>
    </div>
    
</body>
  );
}

export default Verify;