import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import Header from '../../comps/header.jsx';
import Navbar from '../../comps/navbar.jsx';
import '../../styles/styles.css';
import '../../styles/partstyles.css';
import '../../styles/planstyles.css';

function EditPlan() {

  const queryParameters = new URLSearchParams(window.location.search)

    let defaultRow = {
        time: 0, lesson:'0', from:'', to:'', type:'lesson', 
        mo:'',   moteacher:'',  moroom:'',   mocolor:'transparent', 
        di:'',   diteacher:'',  diroom:'',   dicolor:'transparent', 
        mi:'',   miteacher:'',  miroom:'',   micolor:'transparent',
        do:'',   doteacher:'',  doroom:'',   docolor:'transparent',
        fr:'',   frteacher:'',  frroom:'',   frcolor:'transparent'
    }
    const [data, setData] = useState([]);
    const [planname, setName] = useState('Stundenplan');
    const [error, setError] = useState('')
    const navigate = useNavigate();
    const [nextTime, setNextTime] = useState(0);
    const [lesson, setNextLesson] = useState(0);

    let planid = queryParameters.get("planid")

    useEffect(() => {
      console.log(planid);
      fetch(process.env.REACT_APP_URL + '/plan/getplan', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        planid,
    }),
  }).then((response) => {
      return response.json()
  }).then((data) => {
      console.log(data)
      setData(data.plan)
      setName(data.planname)
      if(data.plan.length > 0) {
      console.log(data.plan[data.plan.length-1].time)
      setNextTime(data.plan[data.plan.length-1].time + 1)
      }
  })
  }, []);

    async function handleSave(newdata) {
      //event.preventDefault()
      const response = await fetch(process.env.REACT_APP_URL + '/plan/editplan', {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              data:newdata,
              planid,
              planname:planname,
          }),
      }) 
      const responsedata = await response.json()
      console.log(responsedata)
      
      if (data.authed === true) {
          navigate('/plan')

      } else {
          setError(data.error)
      }
  }



    function handleDataChange(time, value, field) {
      console.log(time, value, field)
      let newData = data.map(row => {
        if(row.time === time){
          return {...row, [field]: value}
        }else return row
      })
      console.log(newData)
      handleSave(newData)
      setData(newData)
    }


    function ColorPicker ({time, day}){
      
       return(
      <select name="color" class="form-control" id="color" onChange={(e) => {handleDataChange(time, e.target.value, day+'color')}}>
        <option value="">Farbe bearbeiten</option>
        <option style={{color:'#509ECE'}} value="#509ECE">&#9724; Blau</option>
        <option style={{color:'#9A75C4'}} value="#9A75C4">&#9724; Violett</option>
        <option style={{color:'#59BD59'}} value="#59BD59">&#9724; Grün</option>                         
        <option style={{color:'#DCB84E'}} value="#DCB84E">&#9724; Gelb</option>
        <option style={{color:'#FCA253'}} value="#FCA253">&#9724; Orange</option>
        <option style={{color:'#F77474'}} value="#F77474">&#9724; Rot</option>
        <option style={{color:'#BABABA'}} value="#BABABA">&#9724; Grau</option>
        <option style={{color:'black'}} value="transparent">&#9724; Transparent</option>                            
      </select>
    )} 

    const lessonlist = data.map((lesson) => (
        <tr key={lesson.time} id={lesson.time} className={lesson.type}>
            <td className={'lessonfield ths ' + lesson.type}><input placeholder="von" value={lesson.from} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'from')} /><input placeholder="Stunde" type="text"  value={lesson.lesson} onChange={(e) => handleDataChange(lesson.time, e.target.value, 'lesson')} /><input placeholder="bis" value={lesson.to} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'to')} /></td>
                <td className={'lessonfield mo ' + lesson.type}><div className="innerlesson" style={{backgroundColor: `${lesson.mocolor}`}}><input placeholder="Fach" value={lesson.mo} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'mo')} /><input placeholder="Lehrer" value={lesson.moteacher} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'moteacher')} /><input placeholder="Raum" value={lesson.moroom} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'moroom')} /><ColorPicker time={lesson.time} day='mo'/></div></td>
                <td className={'lessonfield mo ' + lesson.type}><div className="innerlesson" style={{backgroundColor: `${lesson.dicolor}`}}><input placeholder="Fach" value={lesson.di} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'di')} /><input placeholder="Lehrer" value={lesson.diteacher} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'diteacher')} /><input placeholder="Raum" value={lesson.diroom} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'diroom')} /><ColorPicker time={lesson.time} day='di'/></div></td>
                <td className={'lessonfield mo ' + lesson.type}><div className="innerlesson" style={{backgroundColor: `${lesson.micolor}`}}><input placeholder="Fach" value={lesson.mi} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'mi')} /><input placeholder="Lehrer" value={lesson.miteacher} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'miteacher')} /><input placeholder="Raum" value={lesson.miroom} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'miroom')} /><ColorPicker time={lesson.time} day='mi'/></div></td>
                <td className={'lessonfield mo ' + lesson.type}><div className="innerlesson" style={{backgroundColor: `${lesson.docolor}`}}><input placeholder="Fach" value={lesson.do} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'do')} /><input placeholder="Lehrer" value={lesson.doteacher} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'doteacher')} /><input placeholder="Raum" value={lesson.doroom} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'doroom')} /><ColorPicker time={lesson.time} day='do'/></div></td>
                <td className={'lessonfield mo ' + lesson.type}><div className="innerlesson" style={{backgroundColor: `${lesson.frcolor}`}}><input placeholder="Fach" value={lesson.fr} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'fr')} /><input placeholder="Lehrer" value={lesson.frteacher} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'frteacher')} /><input placeholder="Raum" value={lesson.frroom} type="text" onChange={(e) => handleDataChange(lesson.time, e.target.value, 'frroom')} /><ColorPicker time={lesson.time} day='fr'/></div></td>
        </tr>
    ))

    return (
      <Fragment>
        <Navbar />
          <div className="container">
            <Header page={{title:'Stundenplan bearbeiten'}}/>

            <div className="page">
              
              
          <div className='centralizer'>
          
        <div className="plan">
          <div className="tabletitle">
          <h1><input type="text" value={planname} onChange={(e) => setName(e.target.value)}/></h1>
          </div>
        <table>
        <tr>
            <th className='headerday corner'><FontAwesomeIcon icon={faClock} /></th>
            <th className='headerday'>Mo</th>
            <th className='headerday'>Di</th>
            <th className='headerday'>Mi</th>
            <th className='headerday'>Do</th>
            <th className='headerday'>Fr</th>
        </tr>
        
        {lessonlist}
    </table>
    <div className='planbuttons'>
    <button onClick={async () => {
        let newRow = defaultRow
        newRow.time = nextTime 
        newRow.lesson = (lesson + 1) +". Stunde"
        setNextTime(nextTime+1)
        setNextLesson(lesson+1)
        setData([...data, newRow])
        handleSave([...data, newRow])
      }}>Reihe hinzufügen</button>

      <button onClick={async () => {
        let newRow = defaultRow
        newRow.time = nextTime 
        newRow.lesson = "Pause"
        newRow.type = 'pause'
          setNextTime(nextTime+1)
          
          setData([...data, newRow])
          handleSave([...data, newRow])
      }}>Pause hinzufügen</button>

      <button onClick={async () => {
        let newRow = defaultRow
          if(nextTime > 0) {
            setData(data.filter(row => row.time !== nextTime - 1))
            setNextTime(nextTime-1)
            console.log(nextTime)
          }
      }}>Reihe entfernen</button>
      <button onClick={ () => {
        handleSave()
      }}>Speichern</button>
    </div>
    </div>
    </div>
    </div>
          </div>
        
      </Fragment>

    )
}



export default EditPlan;