import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import randomstring from 'randomstring';
import Header from '../../comps/header.jsx';
import Navbar from '../../comps/navbar.jsx';
import '../../styles/styles.css';
import '../../styles/partstyles.css';
import '../../styles/planstyles.css';
import './manageplans.css'

function ManagePlan() {
    const [timetables, setTimeTables] = useState({});
    const [selected, setSelected] = useState([]);
    const [error, setError] = useState('');
    const [popupOpen, setPopup] = useState(false);
    const [deleteItem, setDeleteItem] = useState();
    const [settings, setSettings] = useState({});
    const [timeStart, setStart] = useState(null)
    const [displayTime, setDT] = useState(null)
    const [activeID, setActiveID] = useState('')

    useEffect(() => {
        fetch(process.env.REACT_APP_URL + '/settings/getsettings', {
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
              }).then((response) => {
                  return response.json()
              }).then((data) => {
                  setSettings(data)
              })
        fetch(process.env.REACT_APP_URL + '/plan/manageplans', {
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
              }).then((response) => {
                  return response.json()
              }).then((data) => {
                    console.log(data)
                        setTimeTables(data.timetables)
                        setSelected(data.selectedtables)
                        let lastSun = getLastDayOfWeek(new Date, 0)
                            lastSun.setDate(lastSun.getDate())
                            lastSun.setUTCHours(0,0,0,0);
                        setStart(lastSun)
                    const lastMon = getLastDayOfWeek(new Date, 1)
                    let dateString = "Montag dem " + lastMon.getDate().toString() + "." + (lastMon.getMonth() + 1).toString() + "." + lastMon.getFullYear().toString()
                    setDT(dateString)
                    if(data.activePlan) if(data.activePlan.id) setActiveID(data.activePlan.id)
                    
              })
        }, []);

        async function post(url, data){
            const response = await fetch(process.env.REACT_APP_URL + '/'+url, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data),
            })
            console.log(response)
        }


    function generateID(){
        let date = new Date
        return date.getDate() + '' + date.getMonth() + '' + date.getFullYear() + '' + date.getHours() + '' + date.getMinutes() + '' + date.getSeconds() + '' + date.getMilliseconds() + randomstring.generate(16)
    }
    
    
    function getLastDayOfWeek(date, dayOfWeek) {
      let resultDate = new Date(date.getTime());
      resultDate.setDate(date.getDate() - (7 - dayOfWeek + date.getDay()) % 7);
      return resultDate;
    }
    
    function getCurrentTableIndex(user){
      const dateAnchor = user.settings.timetableanchor.date
      const startTableID = user.settings.timetableanchor.tableid
      let startObj = user.selectedtables.find((table) => {return table.id == startTableID})

      let date2 = getLastDayOfWeek(new Date, 0)
            date2.setDate(date2.getDate())
            date2.setHours(3);
            date2.setMinutes(0);
            date2.setSeconds(0);
            date2.setMilliseconds(0)

          let difference_in_time = date2.getTime() - new Date(dateAnchor).getTime();
          let difference_in_days = Math.round(difference_in_time / (1000 * 3600 * 24));
        return ((difference_in_days / 7) % user.selectedtables.length) + user.selectedtables.indexOf(startObj)
    }
async function unselectPlan(id){
    let newSelected = selected.filter((table)=>{return table.id !== id})
    if(id === settings.timetableanchor.tableid) {
        let settingid = 'timetableanchor';
        let settingvalue = {date: '', tableid: ''}
        setActiveID("")
        console.log({...settings, [settingid]: settingvalue})
        setSettings({...settings, [settingid]: settingvalue})
        post('settings/setsetting', {id:settingid, settingvalue})
        
    } 
    setSelected(newSelected)
    post('plan/updateplans', {timetables:timetables, selected:newSelected})

    
    }

    async function selectPlan(id) {
    let timetable = timetables[id];
    if (settings.multipleweekplan === true) {
    if (!selected.find((table) => {return table.id === id}) && selected.length <= 3) {
        let newSelected = [...selected, timetable]
        console.log(newSelected)
        setSelected(newSelected);
        post('plan/updateplans', {timetables:timetables, selected:newSelected}) //resethomework
        }
    }else {
        let newSelected = [timetable]
        setSelected(newSelected);
        post('plan/updateplans', {timetables:timetables, selected:newSelected}) //resethomework
    }
    }

    function togglePopup() {
    setPopup(!popupOpen)
    }

    function deletePlan(id) {
        let newList = timetables
        
        let newSelected = selected.filter((table)=>{return table.id !== id})
        if(id === settings.timetableanchor.tableid) {
            let settingid = 'timetableanchor';
            let settingvalue = {date: '', tableid: ''}
            setActiveID("")
            setSettings({...settings, [settingid]: settingvalue})
            post('settings/setsetting', {id:settingid, settingvalue})
        } 
        delete newList[id]
        setTimeTables(newList)
        setSelected(newSelected)
        post('plan/updateplans', {timetables:newList, selected:newSelected})
        post('plan/deleteplan', {id})
    }

    function createNewPlan(){
    const newID = generateID()
    const newList = {...timetables, [newID]: {id: newID, name:'Neuer Stundenplan'}}
    console.log(newList)
    setTimeTables(newList)
    
    post('plan/updateplans', {timetables:newList, selected:selected})
    post('plan/createnewplan', {id:newID})
}

    async function setTimeAnchor(timeStart, planid){
       
        const user = {settings: {timetableanchor:{date: timeStart, tableid: planid}}, selectedtables:selected}
        let currentIndex = getCurrentTableIndex(user)
        let settingvalue = {date: timeStart, tableid: selected.at(currentIndex).id}
        let id = 'timetableanchor'
        console.log({...settings, [id]: settingvalue })
        setSettings({...settings, [id]: settingvalue })
        setActiveID(selected.at(currentIndex).id)

        post('settings/setsetting', {id, settingvalue})
    }
      
  
        const planlist = Object.values(timetables).map((plan) => (  
            
            <div key={plan.id} className="timetable-box"><h4>{plan.name}</h4> <a className="tableoptionbutton" href={`/editplan?planid=${plan.id}`}>bearbeiten </a> 
    
            {(settings.multipleweekplan === true) ? 
                <button className="tableoptionbutton" onClick={() => {selectPlan(plan.id)}}>{selected.length > 3 ? 'Limit erreicht' : 'Festlegen als ' + (selected.length + 1)}.</button> :  
                <button className='tableoptionbutton' onClick={() => {selectPlan(plan.id)}}>festlegen</button>
            }
    
            <button className='tableoptionbutton' onClick={ ()=>{setDeleteItem({name:plan.name, id:plan.id,}); togglePopup()} }>löschen</button></div>
        ))

        const selectedlist = Object.values(selected).map((plan) =>(
                <div className="timetable-box" key={plan.id}>
                    <h4>{plan.name}</h4> 
                    {plan.id === activeID ? (<p>Aktueller Stundenplan</p>) : null}
                    {settings.multipleweekplan === true ? <div>
                         <button className="tableoptionbutton" onClick={()=> {setTimeAnchor(timeStart, plan.id)}}>Festlegen </button> 
                         </div>: null} 
                    <button className="tableoptionbutton" onClick={()=> {unselectPlan(plan.id)}}>entfernen</button></div>
        ))

        function Popup({}){
        
            return (
              (popupOpen) ? 
              <div className='popup'>
                <div className='popup_inner'>
                  <div className='popupheader'>
                  <h1>Löschen?</h1>
                <button onClick={togglePopup}><i><FontAwesomeIcon icon={faXmark}/></i></button>
                </div>
                <div className='popupbody'>
                  <p>
                      Willst du {deleteItem.name} wirklich löschen?
                  </p>
                </div>
                <div className='popupbuttons'>
                <button onClick={togglePopup}>Abbrechen</button>
                <button onClick={()=>{ deletePlan(deleteItem.id); togglePopup()} }>Löschen</button>
                </div>
                </div>
              </div> 
              : null
            );
            
        }

    return (
      <Fragment>
        <Popup/>
        <Navbar />    
        <div className="container">
            <Header page={{title:'Stundenpläne verwalten'}}/>
            <div className="page">
                <div className='vertalign'>
                    <div className="timetablelist">
                        <div className="timetablelist-header">Stundenpläne</div>
                        <div className='timetablelist-inner'>
                            {planlist}
                        </div>
                        <div className='newtimetable'><button onClick={ ()=>{createNewPlan()}}>+ Stundenplan</button></div>
                    </div>

                    <div className='selectedtablelist'>
                    {settings.multipleweekplan == true && selected.length > 0? <div className='ask-time'> <p >Welcher Stundenplan ist ab {displayTime} gültig?</p></div> : null}
                        {selectedlist}
                    </div>
                </div> 
            </div>
        {error}
        </div>    
      </Fragment>
    )
}

export default ManagePlan;