import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Header from '.././/comps/header.jsx';
import Navbar from '.././/comps/navbar.jsx';
import '.././styles/styles.css';
import '.././styles/partstyles.css';





function Assistant() {

    return (
      <Fragment>
        <Navbar />
        <div className="container">
          <Header page={{title:'Assistent'}}/>
          <div className="page">
              
            </div>
        </div>
      </Fragment>

    )
  
}



export default Assistant;