import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, redirect, useNavigate } from "react-router-dom";
import './styles/styles.css';
import Cookie from 'universal-cookie'


 function NoPage() {

      
  return (
    <body id="auth">
    <div className="centerbox">
        <div className="menu-center">
            <div className="title">
                <h2 className="header">Seite nicht gefunden</h2>
            </div>
            

            <a href="/home">zur Startseite</a>
        </div> 
        </div>
    </body>
  );
    }

export default NoPage;