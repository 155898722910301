import { Navigate } from 'react-router-dom'
import { Outlet } from 'react-router'
import { useState, useEffect } from 'react'
const NotAuth = () => {
    const [notAuthed, setNotAuthed] = useState(undefined)

    useEffect(() => {
        const url = process.env.REACT_APP_URL + '/checkauth'
        fetch(url, {
        credentials: 'include',
        mode: 'cors',
        method: 'GET',
    }).then((response) => {
        return response.json()
    }).then((data) => {
        console.log(data)
        if (data.authed != true) {
           setNotAuthed(true) 
        }else {
            setNotAuthed(false)
        }
    })
}, []);
    if( notAuthed === undefined){
        return null
    }
    if(notAuthed == false){
        return <Navigate to='/home'/>
    }

    return <Outlet/>
}


export default NotAuth