import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, redirect, useNavigate } from "react-router-dom";
import './styles/styles.css';
import Cookie from 'universal-cookie'


 function Login() {
    const [username, setName] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const cookie = new Cookie();
    const navigate = useNavigate();

    let token = cookie.get('accessToken')
        
    useEffect(() => {
        //checkAuth()
      });


      
    async function handleLogin(event) {
        event.preventDefault()
        const response = await fetch(process.env.REACT_APP_URL + '/user/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username,
                password,
            }),
        }) 
        const data = await response.json()
        console.log(data)
        
        if (data.status == 'ok') {
            navigate('/home')
            
            cookie.set('accessToken', data.accessToken, {
                maxAge: data.maxAge
            })

        } else if(data.error) {
            setError(data.error)
        }
    }

  return (
    <body id='auth'>
    <div className="centerbox">
        <div className="menu-center">
            <div className="title">
                <h2 className="header">Anmelden</h2>
            </div>
            <form action ="/login" onSubmit={handleLogin} className="form" autocomplete="off">
            <div className="normal-input">
                    <p>
                       Schön dich wieder zu sehen! 
                    </p>
                    
                <div>
                    <input onChange={(e) => setName(e.target.value)}type="text" name="username" placeholder="Name" autocomplete="off"/>
                </div>
                <div>
                    <input onChange={(e) => setPassword(e.target.value)} type="password" name="password" placeholder="Password" autocomplete="off"/>
                </div>
                <div className="error">{error}</div>
            </div>
            
            <input type="submit" className="yellow-btn" value="Einloggen"/>
            
            </form>

            <a href="/signup">Du hast noch keinen Account? Hier registrieren</a>
        </div> 
        </div>
    </body>
  );
    }

export default Login;